<template>
  <div class="GLA">

    <div class="Título">
      <h1>GNU+LINUX*ARG</h1>
    </div>

    <nav class="navbar"></nav>

    <main>
      <section id="info">
        
        <section id="definicion-ventajas">
          <h2>GNU+Linux: O.S. Libre y de código abierto ></h2>
          <p>
            GNU+Linux se erige como una alternativa sólida a los sistemas operativos tradicionales. Su naturaleza libre y de código abierto lo convierte en una opción atractiva para usuarixs que buscan libertad de software, seguridad robusta, estabilidad confiable y personalización a medida.
          </p>
          <p>Las ventajas son numerosas:</p>
          <ul>
            <li>Amplia gama de software</li>
            <li>Compatibilidad versátil</li>
            <li>Eficiencia optimizada</li>
            <li>Escalabilidad adaptable</li>
            <li>Comunidad colaborativa</li>
          </ul>
          <p>
            Más allá de sus características técnicas, GNU+Linux representa una filosofía que empodera a lxs usuarixs y promueve la libertad tecnológica.
          </p>
        </section>

        <section id="comunidad">
          <h3>Argentina, tierra de próceres y mentes brillantes, tiene una larga tradición de innovación tecnológica. Nuestra historia está marcada por un espíritu pionero y colaborativo.</h3>
          <p>
            Fomentar una comunidad GNU+Linux en Argentina no solo es un imperativo para el desarrollo tecnológico nacional, sino también una forma de honrar nuestro legado de innovación y colaboración. Una comunidad sólida nos permitirá:
          </p>
          <ul>
            <li>Compartir conocimiento y experiencia</li>
            <li>Desarrollar software local</li>
            <li>Promover la educación tecnológica</li>
            <li>Contribuir al desarrollo global</li>
          </ul>
          <p>
            Al unir fuerzas como GNU+Linux Argentina, podemos construir un futuro donde la innovación, la colaboración y el acceso libre al conocimiento sean pilares fundamentales del desarrollo nacional.
          </p>
        </section>

        <section id="distros-linux">
          <div class="distros">
          <h2>Distros GNU+Linux:</h2>
          <ul>
            <li>
              <a href="https://www.debian.org/" target="_blank"><strong>Debian:</strong></a>
              Conocida por su estabilidad y comunidad robusta, Debian es una excelente opción para servidores y estaciones de trabajo.
            </li>
            <li>
              <a href="https://www.redhat.com/en/technologies/linux-platforms/enterprise-linux" target="_blank"><strong>Red Hat Enterprise Linux (RHEL):</strong></a>
              Centrada en la estabilidad y el soporte empresarial, RHEL es la opción preferida por grandes organizaciones para entornos de misión crítica.
            </li>
            <li>
              <a href="https://ubuntu.com/" target="_blank"><strong>Ubuntu:</strong></a>
              Basada en Debian, Ubuntu es accesible tanto para novatos como para usuarixs avanzadxs, con un fuerte enfoque en la facilidad de uso.
            </li>
            <li>
              <a href="https://www.centos.org/centos-stream/" target="_blank"><strong>CentOS Stream:</strong></a>
              Derivada de RHEL, CentOS Stream es popular en servidores, ofreciendo un ciclo de vida estable con paquetes actualizados.
            </li>
            <li>
              <a href="https://getfedora.org/" target="_blank"><strong>Fedora:</strong></a>
              Con lo último en software, Fedora es perfecta para desarrolladores y aquellos que quieren estar a la vanguardia tecnológica.
            </li>
            <li>
              <a href="https://www.archlinux.org/" target="_blank"><strong>Arch Linux:</strong></a>
              Arch es conocida por su flexibilidad y control total, ideal para usuarixs avanzadxs que buscan personalizar su entorno al máximo.
            </li>
            <li>
              <a href="https://manjaro.org/" target="_blank"><strong>Manjaro:</strong></a>
              Basada en Arch, Manjaro ofrece una experiencia similar con un enfoque más amigable y fácil de usar para principiantes.
            </li>
            <li>
              <a href="https://linuxmint.com/" target="_blank"><strong>Linux Mint:</strong></a>
              Basada en Ubuntu, Linux Mint es ideal para quienes buscan una experiencia de usuario tradicional y estable.
            </li>
          </ul>
        </div>
        </section>

        <h2>Sumate a la comunidad GNU+Linux Argentina para construir un futuro digital para todxs.</h2>

      </section>
    </main>

      <!--<div class="button5">
        <button onclick="window.open('https://t.me/+a5xwPszG7v5mOTIx', '_blank')" class="btn" aria-label="Grupo">
          GRUPO DE TELEGRAM
        </button>
        <p>GNU+Linux Argentina</p>
      </div>--> 
      
    </div>
</template>

<script>
export default {
  name: 'GLA',
};
</script>

<style scoped>
.navbar {
  background-color: #457B9D;
  padding: 10px;
  font-size: 1em;
}
.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.navbar li {
  margin-right: 20px;
  margin-left: 20px;
}
.distros li {
  margin-top: 20px;
  margin-bottom: 20px;
}
a {
  color: #457B9D;
  transition: color 1s ease;
  font-size: 1em;
  font-weight: bold;
}
a:hover {
    color: #333633;
}
h1 {
  font-size: 2rem;
  color: #457B9D;
  margin-bottom: 1em;
  transition: color 1s ease;
}
h1:hover {
  color: #457B9D;
}
h2 {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Chakra Petch";
  margin: 1.5em 0;
}
h3 {
  font-size: 1.5rem;
  margin: 1.5em 0;
  font-family: "Chakra Petch";
  text-transform: uppercase;
}
h4 {
  font-size: 1.5rem;
  font-family: "Chakra Petch";
  margin: 1.5em 0;
}
.button5 {
  text-align: center;
}
.button5 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.button5 button {
  text-decoration: none;
  color: #060606;
  font-size: 1.5em;
  font-family: "Chakra Petch";
  transition: color 1s ease;
  background-color: #FEF9E6;
  border: 1.5px solid #060606;
  border-radius: 5px;
  padding: 15px 15px;
  margin: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button5 button:hover {
  background-color: #0e1c36;
  color: #FEF9E6;
}
button:active {
  transform: scale(0.95);
}
@media (max-width: 768px) {
  .button5 button {
    font-size: 1em;
    padding: 10px 10px;
  }
}
@media (max-width: 480px) {
  .button5 button {
    font-size: 0.7em;
    padding: 12px 12px;
  }
}
</style>