<template>

  <div class="destacado">
    <h2>{{ titulo }}</h2>
    <div v-html="contenido"></div>
    <a :href="documentoUrl" target="_blank">Continuar leyendo en Google Drive</a>
  </div>

</template>

<script>
export default {
  name: 'DestacadosCont',
  data() {
    return {
      titulo: '',
      contenido: '',
      documentoUrl: ''
    };
  },
  mounted() {
    this.loadDestacado(this.$route.params.id);
  },
  methods: {
    loadDestacado(id) {
      if (id === '1') {
        this.titulo = 'CN > ARTÍCULO 43 BIS/TER';
        this.contenido = 'La democratización informática garantiza a la población el acceso a entornos digitales y virtuales respetuosos de la dignidad humana, incluyendo la privacidad en línea y la libertad de expresión en Internet...';
        this.documentoUrl = 'https://drive.google.com/file/d/1v350ovvyty4fQGrDaDM8rLSRYg10dGlR/view';
      } else if (id === '2') {
        this.titulo = 'PROYECTO DE LEY DE PRESUPUESTOS MÍNIMOS PARA LA PROMOCIÓN DE LA INTELIGENCIA ARTIFICIAL';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objeto y alcance. La presente ley tiene por establecer un marco normativo integral para la promoción de la Inteligencia Artificial (IA), con el fin de asegurar su desarrollo ético, responsable y seguro, y garantizar la protección de los derechos y libertades humanas, la privacidad y la transparencia en su uso, así como su acceso equitativo...';
        this.documentoUrl = 'https://drive.google.com/file/d/1gHYftED_b60uQsgOdtFQdxwdqJUCMrsX/view';
      } else if (id === '3') {
        this.titulo = 'PROYECTO DE LEY DE ACCIONES ESTRUCTURALES DE INCIDENCIA COLECTIVA';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objeto. La presente ley tiene por objeto crear y regular las acciones estructurales de incidencia colectiva, como ‘acciones estructurales’, sean incoadas de forma individual, colectiva o consorcial...';
        this.documentoUrl = 'https://drive.google.com/file/d/14s2qlwndJ5sEKWaBIvKl4I3YWE9Xmn7C/view';
      } else if (id === '4') {
        this.titulo = 'PROYECTO DE LEY NACIONAL DE EDUACIÓN AMBIENTAL Y CONSUMO RESPONSABLE';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objetivo. La presente ley tiene como objetivo establecer un marco educativo integral que fomente la conciencia ambiental y promueva el consumo responsable...';
        this.documentoUrl = 'https://drive.google.com/file/d/1xmdlJgCxeZ9xKyHTwES7QDIwpLEWs-Y8/view';
      } else if (id === '5') {
        this.titulo = 'PROYECTO DE LEY MARCO PARA LAS OPERACIONES CON CRIPTOMONEDAS';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objeto. La presente ley tiene como objetivo establecer un marco regulatorio para el uso, intercambio y comercio de criptomonedas en la República Argentina, promoviendo la transparencia, seguridad y el desarrollo responsable de estas tecnologías...';
        this.documentoUrl = 'https://drive.google.com/file/d/1aNbMe8iLBT896tkeLoLEArYrNVZbgrSd/view';
      } else if (id === '6') {
        this.titulo = 'PROYECTO DE LEY DE INGRESO BÁSICO UNIVERSAL AUTOMÁTICO (IBUA)';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Definición y objetivo: Créase el Ingreso Básico Universal Automático (IBUA) como un derecho personal inalienable e irrenunciable, destinado a asegurar un nivel de vida digno para todos los residentes en el territorio nacional...';
        this.documentoUrl = 'https://drive.google.com/file/d/1mfBXne1BOSPMsWJS4RHDQHh_LqPYIvzB/view';
      } else if (id === '7') {
        this.titulo = 'PROYECTO DE LEY DE DESARROLLO COMUNITARIO';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Creación. Créase el Fondo para el Desarrollo Comunitario con el objeto de contribuir a la reducción de la desigualdad económica y promover el bienestar social mediante la financiación de programas y proyectos destinados a atender las necesidades básicas de la población, priorizando a los sectores más vulnerables...';
        this.documentoUrl = 'https://drive.google.com/file/d/1F4AVlMzsEHAaUR37daFJ_EyCNnAXHjcQ/view';
      } else if (id === '8') {
        this.titulo = 'PROYECTO DE LEY DE PROTECCIÓN DE DATOS BIOMÉTRICOS';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objeto. Esta ley tiene por objeto la protección de los datos biométricos entendidos como cualquier dato personal obtenido a partir de una característica física, biológica o conductual única de una persona, incluyendo, entre otros, huellas digitales, geometría de la mano, retina, iris, patrón de voz y reconocimiento facial, que pueda ser utilizado para identificar inequívocamente a una persona...';
        this.documentoUrl = 'https://drive.google.com/file/d/14uUAWVWq58zU4_gJnp3HDSIu0uY85VPT/view';
      } else if (id === '9') {
        this.titulo = 'PROYECTO DE LEY DE DEMOCRATIZACIÓN DE LA ROBÓTICA';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objeto. La presente ley tiene por objeto establecer un marco legal integral para la investigación, desarrollo, producción, implementación y uso de robots, conforme a los más altos estándares internacionales en materia de derechos humanos...';
        this.documentoUrl = 'https://drive.google.com/file/d/1XwAbz6Nss4DeAnVnLejCONb_9iYwDDpL/view';
      } else if (id === 'I') {
        this.titulo = 'PROYECTO DE LEY DE RETORNOS EDUCATIVOS';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objeto. La presente ley tiene por objeto establecer un mecanismo legal para que las empresas privadas retornen parte de los beneficios económicos derivados de la formación académica pública, a través de aportes económicos o prestaciones de servicio, gestionados por el Consejo Interuniversitario Nacional, destinados al fortalecimiento de las universidades públicas nacionales...';
        this.documentoUrl = 'https://drive.google.com/file/d/1-0BJInmDFGyP_MclTrfGGzjEhLRls2qF/view';
      } else if (id === 'II') {
        this.titulo = 'PROYECTO DE MEGA LEY DE PROTECCIÓN DE LOS RECURSOS NATURALES';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objeto. La presente ley tiene por objeto establecer un marco legal integral para la protección extrema de los recursos naturales en Argentina...';
        this.documentoUrl = 'https://drive.google.com/file/d/1AZiaW5N-kLqQcVd4cbEpXhdTcMYxqhLh/view';
      } else if (id === 'III') {
        this.titulo = 'PROYECTO DE LEY DE CREACIÓN DE ADRAX';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objeto. Créase la Agencia contra la Discriminación, el Racismo y la Xenofobia (ADRAX) como entidad autárquica con autonomía funcional y financiera, con el objetivo de prevenir, detectar, erradicar y sancionar toda forma de discriminación...';
        this.documentoUrl = 'https://drive.google.com/file/d/1YeA93yl8cbFFF1mAn876jwq7tGEr-8Ih/view';
      } else if (id === 'IV') {
        this.titulo = 'PROYECTO DE LEY DE CONSUMO RESPONSABLE DE PLATAFORMAS DIGITALES';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objetivo del programa. Créase el Programa de Promoción del Consumo Responsable de Plataformas Digitales con el objetivo de concientizar a la población sobre el abuso de dispositivos y plataformas en línea...';
        this.documentoUrl = 'https://drive.google.com/file/d/1HV_uCYeLa2-L4Z23Vx5zJg9q4KOJiyRp/view';
      } else if (id === 'V') {
        this.titulo = 'PROYECTO DE LEY NACIONAL DE EDUCACIÓN Y CONCIENTIZACIÓN FINANCIERA';
        this.contenido = 'El Senado y la Cámara de Diputados, sancionan con fuerza de Ley:<br><br>Artículo 1. Objeto. La presente ley tiene por objeto incorporar la educación financiera obligatoria y transversal en el sistema educativo argentino para todos los estudiantes a partir de los 13 años de edad, con el fin de promover la alfabetización financiera, la toma de decisiones económicas informadas, y el fortalecimiento de una cultura de la responsabilidad financiera...';
        this.documentoUrl = 'https://drive.google.com/file/d/1H-0pL9tQUZirJyTH_mCDS08676caCu39/view';
      } else {
        this.titulo = 'Destacado no encontrado';
        this.documentoUrl = '';
      }
    }
  }
}
</script>