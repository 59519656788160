<template>
  <div class="main-container">
    <button @click="toggleTheme" class="theme-toggle" aria-label="Cambiar tema">
      {{ theme === 'light' ? '🌙' : '☀️' }}
    </button>

    <div class="titulo" data-aos="fade-down">
      <div class="logx2">
        <img
          :src="currentLogo"
          :alt="`Logo de Julieta Calderon en modo ${theme}`"
        />
      </div>
      <h1>JULIETA CALDERON</h1>
      <h2>Abogada && FS Dev.</h2>
    </div>
  
    <div class="contenedor1" id="servicios" data-aos="fade-up">
      <h4>[ Asesoramiento y Patrocinio Jurídico ]</h4>
      <ul class="service-list">
        <li v-for="(service, index) in servicios" :key="index">
          <button class="btn-category">
            {{ service }}
          </button>
        </li>
      </ul>
      <h4>[ Desarrollo web y Soluciones Tecnológicas ]</h4>
      <ul class="service-list2">
        <li v-for="(service2, index) in servicios2" :key="index">
          <button class="btn-category">
            {{ service2 }}
          </button>
        </li>
      </ul>
      <div class="button2">
        <h4>Para consultar sobre mis servicios o elegir las opciones disponibles:</h4>
        <button @click="openForm" class="btn-consulta" aria-label="Formulario de consulta">
          FORMULARIO DE CONSULTA
        </button>
      </div>
    </div>

    <div class="contenedor2" data-aos="fade-up">
      <h3>PROYECTOS NORMATIVOS</h3>
      <div class="destacados">
        <div v-for="project in proyectos" :key="project.id" class="destacado-card">
          <router-link :to="project.link" class="destacado-link">
            {{ project.name }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="contenedor4" data-aos="fade-up">
      <h3>PRÓXIMAMENTE:</h3>
      <div class="libro4">
      <router-link to="/gla" class="logx">
        <img src="@/assets/AR/img12.png" alt="Próximo libro">
      </router-link>
      </div>
      <div class="libro3">
      <router-link to="/libro" class="logx">
        <img src="@/assets/AR/stories.png" alt="Próximo libro">
      </router-link>
      </div>
      <div class="libro1">
        <router-link to="/" class="logx">
          <img src="@/assets/AR/img11.png" alt="Próximo libro2">
        </router-link>
      </div>
    </div>

    <DestacadosCont v-if="selectedComponent === 'DestacadosCont'" />
  </div>
</template>

<script>
import DestacadosCont from './DestacadosCont.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logoClaro from '@/assets/AR/logo-claro.png';
import logoOscuro from '@/assets/AR/logo-oscuro.png';

export default {
  name: 'InicioCont',
  components: {
    DestacadosCont,
  },
  data() {
    return {
      servicios: [
        'CONSTITUCIONAL',
        'ADMINISTRATIVO',
        'TRABAJO',
        'CONSUMO',
        'AMBIENTE',
        'INCIDENCIA COLECTIVA',
        'DISCRIMINACIÓN',
        'TECNOLOGÍA',
      ],
      servicios2: [
        'HTML',
        'CSS',  
        'JAVASCRIPT',
        'PYTHON',
        'SQL',
        'AI',
        'UX/UI',
        'LINUX',
      ],
      proyectos: [
        { id: 9, name: 'ROBÓTICA', link: '/destacado/9' },
        { id: 8, name: 'DATOS BIOMÉTRICOS', link: '/destacado/8' },
        { id: 7, name: 'DESARROLLO COMUNITARIO', link: '/destacado/7' },
        { id: 6, name: 'IBUA', link: '/destacado/6' },
        { id: 5, name: 'CRIPTOMONEDAS', link: '/destacado/5' },
        { id: 4, name: 'EDUC. AMB. CONS.', link: '/destacado/4' },
        { id: 3, name: 'ACCIONES ESTRUCTURALES', link: '/destacado/3' },
        { id: 2, name: 'INTELIGENCIA ARTIFICIAL', link: '/destacado/2' },
        { id: 1, name: 'CN > 43 BIS/TER', link: '/destacado/1' },
      ],
      selectedComponent: null,
      theme: 'dark',
      logoClaro,
      logoOscuro,
    };
  },
  computed: {
    currentLogo() {
      return this.theme === 'light' ? this.logoClaro : this.logoOscuro;
    },
  },
  mounted() {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      this.theme = savedTheme;
      document.documentElement.setAttribute('data-theme', this.theme);
    } else {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.theme = 'dark';
        document.documentElement.setAttribute('data-theme', this.theme);
      } else {
        this.theme = 'light';
        document.documentElement.setAttribute('data-theme', this.theme);
      }
    }

    AOS.init();
    this.$nextTick(() => {
      AOS.refresh();
    });
  },
  methods: {
    /**
     * @param {string} component
     */
    navigate(component) {
      this.selectedComponent = component;
      this.showContent = false;
    },
    toggleTheme() {
      this.theme = this.theme === 'light' ? 'dark' : 'light';
      document.documentElement.setAttribute('data-theme', this.theme);
      localStorage.setItem('theme', this.theme);
    },
    openForm() {
      window.open('https://forms.gle/8sAhUefgFMZ87mNf9', '_blank');
    },
  },
};
</script>

<style scoped>
[data-aos="fade-down"],
[data-aos="fade-up"],
[data-aos="zoom-in"],
[data-aos="fade-right"] {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

[data-aos].aos-animate {
  opacity: 1;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 20px;
  gap: 60px;
}

/* Botón de toggle de tema */
.theme-toggle {
  position: fixed;
  top: 120px;
  right: 20px;
  background-color: var(--color-fondo);
  color: var(--color-fondo);
  border: none;
  border-radius: 25px;
  padding: 10px 15px;
  cursor: pointer;
  font-family: "Lora", serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.theme-toggle:hover {
  transform: scale(1.05);
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.titulo .logx2 {
  width: 150px;
  height: 150px;
  position: relative;
}

.titulo .logx2 img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
}

[data-theme="light"] .logx2 img[alt*="claro"] {
  opacity: 1;
  z-index: 2;
}

[data-theme="light"] .logx2 img[alt*="oscuro"] {
  opacity: 0;
  z-index: 1;
}

[data-theme="dark"] .logx2 img[alt*="claro"] {
  opacity: 0;
  z-index: 1;
}

[data-theme="dark"] .logx2 img[alt*="oscuro"] {
  opacity: 1;
  z-index: 2;
}

.titulo h1 {
  font-size: 3em;
  margin: 0;
}

.titulo h2 {
  font-size: 1.8em;
  font-weight: normal;
  margin: 0;
}

.contenedor1 {
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.service-list {
  list-style: none;
  padding: 0;
  margin-bottom: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-list2 {
  list-style: none;
  padding: 0;
  margin-bottom: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-category {
  padding: 10px 20px;
  margin: 5px;
  font-family: "Lora", sans-serif;
  background-color: var(--color-boton);
  color: #020202;
  border: none;
  border-radius: 5px;
  font-size: 0.8em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-category:hover {
  background-color: var(--color-boton-hover);
  color: #fef9e6;
}

.button2 {
  margin-top: 20px;
}

.contenedor2 {
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.destacados {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.destacado-card {
  background-color: var(--color-fondo);
  border: 1px solid var(--color-borde);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.destacado-card:hover {
  transform: translateY(-5px);
}

.destacado-link {
  text-decoration: none;
  color: var(--color-texto);
  font-size: 0.9em;
  font-family: "Lora";
  text-align: center;
  justify-content: center;
  margin-bottom: 1em;
  display: block;
}

.contenedor3,
.contenedor4 {
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.button2 button {
  background-color: var(--color-button);
  font-family: "Chakra Petch", sans-serif;
  color: var(--color-base-oscuro);
  font-size: 1em;
  border: 1.5px solid var(--color-base-oscuro);
  border-radius: 5px;
  padding: 15px 15px;
  margin: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button2 button:hover {
  background-color: var(--color-button-hover);
  color: var(--color-base-oscuro);
  transform: scale(1.1);
}

.button3 {
  text-decoration: none;
  padding: 25px 125px;
  font-size: 1.3em;
  font-family: "Chakra Petch", sans-serif;
  font-weight: bolder;
  background-color: var(--color-borde);
  color: #020202;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button3:hover {
  background-color: var(--color-boton-hover2);
  color: #fef9e6;
}

.logx {
  text-align: center;
  margin-top: 20px;
}

.logx2 img {
  animation: rotate 30s linear infinite;
}

.logx img {
  width: 100%;
  border-radius: 10px;
  transition: filter 0.3s ease-in-out;
}

.logx img:hover {
  filter: hue-rotate(200deg)
}

.libro1, .libro2, .libro3 {
  margin-top: 2em;
}
.libro1 {
  margin-bottom: 2em;
}

@media (max-width: 768px) {
  .button2 button {
    font-size: 1em;
    padding: 10px;
  }
  .button3 {
    font-size: 1.2em;
    padding: 24px 84px;
  }
  .titulo h1 {
    font-size: 1.5em;
  }
  .titulo h2 {
    font-size: 1em;
  }
  .destacados {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 0.5em;
  }
}

@media (max-width: 480px) {
  .button2 button {
    font-size: 0.8em;
    padding: 10px;
  }
  .button3 {
    font-size: 1.1em;
    padding: 23px 83px;
  }
}
</style>