<template>
    <div class="libro-cont">

      <div class="libro-CDJ">
        <h2>CARTAS DE JULIETA</h2>

        <div class="capitulo">
          <h3>CDJ I: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ II: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ III: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ IV: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ V: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ VI: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ VII: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ VIII: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ IX: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ X: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XI: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XII: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XIII: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XIV: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XV: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XVI: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XVII: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XVIII: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XIX: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XX: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XXI: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>CDJ XXII: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'CDJCont',
  };
  </script>

  <style scoped>
h3 {
  color: #4a4843;
  font-family: "Lora";
}
.highlight {
  color: #4a4843;
}
</style>