import { createRouter, createWebHistory } from 'vue-router';
import InicioCont from './components/InicioCont.vue';
import LibroCont from './components/LibroCont.vue';
import DestacadosCont from './components/DestacadosCont.vue';
import GnulinuxargCont from './components/GnulinuxargCont.vue';
import CDJCont from './components/CDJCont.vue';

const routes = [
  {
    path: '/',
    name: 'InicioCont',
    component: InicioCont,
    props: true,
  },
  {
    path: '/libro',
    name: 'LibroCont',
    component: LibroCont,
  },
  {
    path: '/CDJ',
    name: 'CDJCont',
    component: CDJCont,
  },
  {
    path: '/gla',
    name: 'GnulinuxargCont',
    component: GnulinuxargCont,
  },
  {
    path: '/destacado/:id',
    name: 'DestacadosCont',
    component: DestacadosCont,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;